.unit-feedbacks-v1 {
	.ptitle {
		display: inline-block;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;

		.rating {
			margin: 0 30px 15px 0;

			.fa {
				color: var(--color-secondary)
			}
		}
	}

	.feedbacks {

		clear: left;
		padding-top: 15px;

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding: 10px;
				border-left: 3px dotted var(--color-primary);
				font-size: var(--font-size-md);
				background-color: var(--color-white);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}