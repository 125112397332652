.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		padding: .5rem .75rem;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: 1.25rem;

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	.card-body {
		p {
			font-size: 1.125rem;
		}
	}

}