.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

	#accordion {
		.card-accrd {
			margin-bottom: 30px !important;
		}

		.card-text-header {
			.img-accrd {
				width: 100%;
				height: 280px;
				display: inline-block;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			button {
				width: 100%;
				text-align: left;
				padding: 0;
				border: none;

				span {
					background-color: var(--color-secondary);
					color: var(--color-white);
					padding: 10px;
					display: block;
					font-size: 16px;
				}
			}

		}

		.card-text-body {
			border: 1px solid #eee;
			padding: 15px;
		}
	}



}