.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {
		position: relative;

		.rating {
			background-color: var(--color-primary);
			padding: 2px 5px;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 2;
		}

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-secondary);
	}

}