.footer-v1 {
	position: relative;

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-secondary);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}

		.newsletter_main_container {
			margin-top: -110px;
			background: var(--color-secondary);
			padding: 30px;
			border-radius: 10px;
			text-align: center;
			margin-bottom: 50px;
			color: var(--color-white);

			.newsletter_block {
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media (max-width:767px) {
					flex-direction: column;
				}

				.newsletter_text {
					margin: 0 auto;
					text-align: left;
					display: -webkit-box;
					align-items: center;
					padding: 0;
					width: 50%;

					@media (max-width:767px) {
						width: 100%;
					}

					p {
						margin: 0;
						color: var(--color-white);
						font-size: 18px;

						@media (max-width:767px) {
							margin-bottom: 15px;
						}
					}
				}

				.newsletter_input_bl {
					display: flex;
					align-items: center;
					width: 50%;
					display: -webkit-box;
					display: flex;
					justify-content: center;

					@media (max-width:767px) {
						width: 100%;
					}

					.newsletter_input_col {
						display: flex;
						justify-content: space-around;
						align-items: center;
						background-color: var(--white);
						color: var(--color-secondary);
						border-radius: 30px;
						border: 4px solid var(--color-secondary);
						padding-left: 15px;
						display: -webkit-box;
						display: flex;
						max-width: 420px;
						width: 100%;
						margin: 0 auto;

						button {
							padding: 12px 40px;
							border-radius: 30px;
							color: var(--white);
							background: var(--color-primary);
						}

						input {
							&::-webkit-input-placeholder {
								font-style: normal;
								color: var(--font-color-main);
							}

							&:-ms-input-placeholder {
								font-style: normal;
								color: var(--font-color-main);
							}

							&::placeholder {
								font-style: normal;
								color: var(--font-color-main);
							}
						}
					}
				}
			}
		}

		.contact-info {
			p {
				margin: 0 0 5px;
				line-height: 32px;
			}

			ul {
				li {
					margin-bottom: 12px;
				}
			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		p {
			margin: 0
		}

		.copy-logo {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.v-office-logo {
				img {
					filter: brightness(0.3);
				}
			}
		}

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-left: 20px;


			img {
				width: 50px;
			}
		}
	}

}

.lastview_bl {
	padding-bottom: 100px;
	position: relative;

	&:before {
		padding-bottom: 150px;
		background-color: transparent;
		background-image: linear-gradient(180deg, hsl(209deg 100% 93% / 97%) 30%, #a3c3d5);
		opacity: 0.3;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
}