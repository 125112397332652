.static-view {
	margin-top: var(--page-margin-top);

	&.partner-v1 {
		.uberuns_thumb_bl {
			position: relative;

			@media (max-width:767px) {
				margin-bottom: 30px;
			}



			img {
				width: 100%;
			}

			&:before {
				content: "";
				background: var(--color-secondary);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
			}
		}

		.uberuns_content_bl {
			padding-right: 50px;
		}
	}
}